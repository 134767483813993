import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import myConfiguredSanityClient from "../../sanityClient"
import imageUrlBuilder from "@sanity/image-url"

// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Icon from "../assets/sis_circle.svg"

const builder = imageUrlBuilder(myConfiguredSanityClient)

const News = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      sanityNews {
        title
        description
        image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
            url
          }
          hotspot {
            x
            y
          }
        }
      }
      sanityEmployees(_id: { eq: "employees" }) {
        employee {
          position
          name
          bio
          image {
            asset {
              url
            }
            hotspot {
              x
              y
            }
          }
        }
      }
      sanityRules {
        fileArray {
          asset {
            url
            originalFilename
          }
        }
      }
      allSanityPost(sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            title
            slug {
              current
            }
            mainImage {
              hotspot {
                height
                width
                x
                y
              }
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  //   const positionStyles = {
  //     backgroundImage: `url(${data.sanityNews.image.asset.fluid.src})`,
  //     backgroundPositionX: `${data.sanityNews.image.hotspot.x * 100}%`,
  //     backgroundPositionY: `${data.sanityNews.image.hotspot.y * 100}%`,
  //     // backgroundSize: "100%",
  //   }

  function getFocalPointsX(data) {
    let xValue = 0.5

    if (data.image.hotspot !== null) {
      xValue = data.image.hotspot.x
    }

    return xValue
  }

  function getFocalPointsY(data) {
    let yValue = 0.5

    if (data.image.hotspot !== null) {
      yValue = data.image.hotspot.y
    }

    return yValue
  }

  function urlFor(source) {
    return builder.image(source)
  }

  function introImageHasLoaded(e) {
    e.target.classList.add("showImage")
  }
  return (
    <Layout>
      <SEO title="Nyheder" description={data.sanityNews.description} />

      <section className="news">
        <div className="introSection">
          <div className="introWrapper">
            <div className="imageWrapper">
              <img
                className="image"
                onLoad={introImageHasLoaded}
                src={urlFor(data.sanityNews.image.asset.url)
                  .focalPoint(
                    getFocalPointsX(data.sanityNews),
                    getFocalPointsY(data.sanityNews)
                  )
                  .width(500)
                  .height(500)
                  .fit("crop")
                  .auto("format")
                  .url(data.sanityNews.image.asset.fluid.src)}
                alt={data.sanityNews.title}
              />{" "}
              <Img
                fluid={data.sanityNews.image.asset.fluid}
                alt={data.sanityNews.title}
              />
              <Icon className="fadeinSVG" />
            </div>
            <div className="introText">
              <h1 className="titleFadeIn">{data.sanityNews.title}</h1>
              <p className="descriptionFadeIn">{data.sanityNews.description}</p>
              <div className="whiteSpace"></div>
            </div>
          </div>
        </div>

        <div className="newsSection">
          <div className="newsGrid">
            {data.allSanityPost.edges.map(function (news) {
              return (
                <div className="newsItem">
                  <AniLink
                    to={`/nyheder/${news.node.slug.current}/`}
                    cover
                    bg="#69b56b"
                  >
                    <Img
                      fluid={news.node.mainImage.asset.fluid}
                      alt={news.node.title}
                      style={{ height: 200 }}
                      className="newsPostImage"
                    />
                    <h3>{news.node.title}</h3>
                    <AniLink
                      cover
                      bg="#69b56b"
                      className="linkTemp"
                      to={`/nyheder/${news.node.slug.current}/`}
                    >
                      Læs mere
                    </AniLink>
                  </AniLink>
                </div>
              )
            })}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default News
